<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-06-22 10:52:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-22 10:59:45
-->
<template>
  <div class="sankey_diagram">
    <div id="sankey" style="width: 100%; height: 400px"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  name: 'sankey_diagram',
  
  data() {
    return {

    }
  },

  computed: {},

  props: {
    option: {
      type: Object,
      default: {}
    }
  },

  mounted() {
    this.getCharts()
  },

  methods: {
    getCharts() {
      let myChart = echarts.init(document.getElementById('sankey'))
      myChart.setOption(this.option)
    }
  },
}
</script>

<style lang='scss' scoped>
</style>